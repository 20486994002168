import { spotlightTheme } from './themes'

/**
 * @title spotlights
 * @description This file contains the spotlights for the PennyLane website.
 *
 * Directory:
 * Some helpful links for configuring spotlights:
 * ----------------------------------------------
 * @link Readme file://./README.md}
 * Documentation on how to configure spotlights.
 * @link Themes file://./themes.ts}
 * Themes are used to style the spotlights.
 * @link Templates file://./templates.md}
 * Templates are copy and paste-able code snippets that can be used to create new spotlights.
 */

export default {
  /**
   * @title home_page
   * @description
   * This spotlight is located on the home page of the website.
   * @link https://pennylane.ai/
   */
  home_page: {
    hidden: false,
    announcements: [
      {
        title: 'LIGHTNING-GPU MEETS CATALYST',
        description:
          'PennyLane’s Lightning-GPU simulator device is now integrated with Catalyst, enabling high-performance quantum simulations on NVIDIA GPUs.',
        image:
          'https://assets.cloud.pennylane.ai/pennylane_website/spotlights/Spotlight_Lightning_GPU_Catalyst.png',
        image_right_aligned: false,
        alt: '',
        links: [
          {
            link_text: 'Read the demo',
            link: '/qml/demos/how_to_catalyst_lightning_gpu',
          },
          {
            link_text: 'Visit the Lightning-GPU documentation',
            link: 'https://docs.pennylane.ai/projects/lightning/en/stable/lightning_gpu/device.html',
          },
        ],
        colours: spotlightTheme.theme_2,
      },
      {
        title: 'PIONEERING THE FUTURE OF QUANTUM COMPUTING',
        description:
          "PennyLane is at the forefront of quantum computing research. Take an in-depth look at groundbreaking advancements and discoveries.",
        image:
          'https://assets.cloud.pennylane.ai/pennylane_website/spotlights/Spotlight_Generic__PAPERS.png',
        image_right_aligned: true,
        alt: '',
        links: [
          {
            link_text: 'See how PennyLane can fuel your research',
            link: '/research',
          },
          {
            link_text: 'Interested in showcasing your research?',
            link: '/get-involved',
          },          
        ],
        colours: spotlightTheme.theme_1,
      },
    ],
  },

  /**
   * @title education_page
   * @description This located on the education page of the website.
   * @link https://pennylane.ai/education/
   */
  education_page: {
    hidden: false,
    announcements: [
      {
        title: 'PENNYLANE FUNDAMENTALS CODEBOOK MODULE',
        description:
          "Accelerate your quantum research breakthroughs with PennyLane! Quickly learn fundamental functions and practical applications of theoretical concepts in this brand-new module.",
        image:
          'https://assets.cloud.pennylane.ai/pennylane_website/spotlights/Spotlight_CodebookLaucnh_2024-04-30.png',
        image_right_aligned: true,
        alt: '',
        links: [
          {
            link_text: 'Learn more',
            link: '/codebook/pennylane-fundamentals',
          },
        ],
        colours: spotlightTheme.theme_2,
      },        
      {
        title: 'EASILY NAVIGATE THE PENNYLANE CODEBOOK',
        description:
          "Delve into the advanced topic you've been searching for, or explore some of the most popular learning paths taught by over 130 universities across 35 countries worldwide!",
        image:
          'https://assets.cloud.pennylane.ai/pennylane_website/spotlights/Spotlight_PLCodebookMap.png',
        image_right_aligned: false,
        alt: '',
        links: [
          {
            link_text: 'Start here!',
            link: '/codebook/learning-paths',
          },
        ],
        colours: spotlightTheme.theme_1,
      },
    ],
  },

  /**
   * @title learn_page
   * @description This spotlight is located on the learn page of the website.
   * @link https://pennylane.ai/qml/
   */
  learn_page: {
    hidden: false,
    announcements: [
      {
        title: 'EASILY NAVIGATE THE PENNYLANE CODEBOOK',
        description:
          "Delve into the advanced topic you've been searching for, or explore some of the most popular learning paths taught by over 110 universities across 34 countries worldwide!",
        image:
          'https://assets.cloud.pennylane.ai/pennylane_website/spotlights/Spotlight_PLCodebookMap.png',
        image_right_aligned: false,
        alt: '',
        links: [
          {
            link_text: 'Start here!',
            link: '/codebook/learning-paths',
          },
        ],
        colours: spotlightTheme.theme_1,
      },
      {
        title: 'FRENCH CODEBOOK MODULES NOW AVAILABLE',
        description: "As a proudly Canadian quantum software framework, we're excited to now offer five modules of the PennyLane Codebook in French.",
        image: 'https://assets.cloud.pennylane.ai/pennylane_website/spotlights/Spotlight_CodebookLaucnh_2024-04-30.png',
        image_right_aligned: true,
        alt: '',
        links: [
          {
            link_text: 'Check them out!',
            link: '/codebook/01-introduction-to-quantum-computing/01-all-about-qubits/fr/',
          },
        ],
        colours: spotlightTheme.theme_2,
      }
    ],
  },

  /**
   * @title performance_page
   * @description This spotlight is located on the performance page of the website.
   * https://pennylane.ai/performance/
   */
  performance_page: {
    hidden: false,
    announcements: [
      {
        title: 'LIGHTNING-GPU MEETS CATALYST',
        description:
          'PennyLane’s Lightning-GPU simulator device is now integrated with Catalyst, enabling high-performance quantum simulations on NVIDIA GPUs.',
        image:
          'https://assets.cloud.pennylane.ai/pennylane_website/spotlights/Spotlight_Lightning_GPU_Catalyst.png',
        image_right_aligned: false,
        alt: '',
        links: [
          {
            link_text: 'Read the demo',
            link: '/qml/demos/how_to_catalyst_lightning_gpu',
          },
          {
            link_text: 'Visit the Lightning-GPU documentation',
            link: 'https://docs.pennylane.ai/projects/lightning/en/stable/lightning_gpu/device.html',
          },
        ],
        colours: spotlightTheme.theme_2,
      },
      {
        title: 'PERFORMANCE',
        description:
          'PennyLane is faster than ever. Learn how to implement large-scale simulations on supercomputers and GPUs with our latest paper and blog post.',
        image:
          'https://assets.cloud.pennylane.ai/datasets/spotlight/Spotlight_HPC4UandMe.png',
        image_right_aligned: true,
        alt: '',
        links: [
          {
            link_text: 'Read the paper',
            link: 'https://arxiv.org/abs/2403.02512',
          },
          {
            link_text: 'Check out the blog',
            link: 'https://pennylane.ai/blog/2024/03/hpc-4-u-and-me/',
          },
        ],
        colours: spotlightTheme.theme_2,
      },
    ],
  },

  /**
   * @title install_page
   * @description This spotlight is located on the install page of the website.
   * @link https://pennylane.ai/install/
   */
  install_page: {
    hidden: false,
    announcements: [
      // {
      //   title: 'SURVEY CLOSES OCTOBER 31, 2024',
      //   description:
      //     'The Unitary Fund 2024 Quantum Open Source Software Survey is here! Share your voice to help build a better quantum computing ecosystem.',
      //   image:
      //     'https://assets.cloud.pennylane.ai/pennylane_website/spotlights/Spotlight_UnitaryFundSurvey.png',
      //   image_right_aligned: false,
      //   alt: '',
      //   links: [
      //     {
      //       link_text: 'Take the survey',
      //       link: 'https://www.surveymonkey.com/r/qosssurvey24',
      //     },
      //   ],
      //   colours: spotlightTheme.theme_1,
      // },
    ],
  },

  /**
   * @title get_involved_page
   * @description This spotlight is located on the get involved page of the website.
   * @link https://pennylane.ai/get-involved/
   */
  get_involved_page: {
    hidden: true,
    announcements: [
      {
        title: 'PENNYLANE SPRING BADGE CHALLENGE',
        description:
          'April 11–30 | Complete all beginner & novice challenges to earn the *Good Day Sunshine* limited-edition badge.',
        image:
          'https://assets.cloud.pennylane.ai/pennylane_website/spotlights/Spotlight_PLBadge_GoodDaySunshine_2024-04-04.png',
        image_right_aligned: false,
        alt: '',
        links: [
          {
            link_text: 'Earn this limited-edition badge now',
            link: 'https://pennylane.ai/challenges/',
          },
          {
            link_text: 'Prep with the Getting Started challenge',
            link: 'https://pennylane.ai/challenges/getting_started/',
          },
        ],
        colours: spotlightTheme.theme_1,
      },
    ],
  },

  /**
   * @title features_page
   * @description This spotlight is located on the features page of the website.
   * @link https://pennylane.ai/features/
   */
  features_page: {
    hidden: false,
    announcements: [
      {
        title: 'NEW RELEASE',
        description:
          'PennyLane v0.40 and Catalyst v0.10 are out, with features including efficient state preparation methods, enhanced QSVT functionality, vibrational Hamiltonians, PennyLane Labs, custom compiler plugins and more!',
        image:
          'https://assets.cloud.pennylane.ai/pennylane_website/spotlights/Spotlight_PLv0.40.png',
        image_right_aligned: false,
        alt: '',
        links: [
          {
            link_text: 'Read the blog post!',
            link: '/blog/2025/01/pennylane-release-0.40/',
          },
        ],
        colours: spotlightTheme.theme_1,
      },
    ],
  },

  /**
   * @title codebook_page
   * @description This spotlight is located on the codebook page of the website.
   * @link https://pennylane.ai/codebook/
   */
  codebook_page: {
    hidden: false,
    announcements: [
      {
        title: 'PENNYLANE FUNDAMENTALS CODEBOOK MODULE',
        description:
          "Accelerate your quantum research breakthroughs with PennyLane! Quickly learn fundamental functions and practical applications of theoretical concepts in this brand-new module.",
        image:
          'https://assets.cloud.pennylane.ai/pennylane_website/spotlights/Spotlight_CodebookLaucnh_2024-04-30.png',
        image_right_aligned: true,
        alt: '',
        links: [
          {
            link_text: 'Learn more',
            link: '/codebook/pennylane-fundamentals',
          },
        ],
        colours: spotlightTheme.theme_2,
      },     
      {
        title: 'EASILY NAVIGATE THE PENNYLANE CODEBOOK',
        description:
          "Delve into the advanced topic you've been searching for, or explore some of the most popular learning paths taught by over 110 universities across 34 countries worldwide!",
        image:
          'https://assets.cloud.pennylane.ai/pennylane_website/spotlights/Spotlight_PLCodebookMap.png',
        image_right_aligned: false,
        alt: '',
        links: [
          {
            link_text: 'Start here!',
            link: '/codebook/learning-paths',
          },
        ],
        colours: spotlightTheme.theme_1,
      },
    ],
  },

  /**
   * @title challenges_page
   * @description This spotlight is located on the challenges page of the website.
   * @link https://pennylane.ai/challenges/
   */
  challenges_page: {
    hidden: false,
    announcements: [
      // {
      //   title: 'SURVEY CLOSES OCTOBER 31, 2024',
      //   description:
      //     'The Unitary Fund 2024 Quantum Open Source Software Survey is here! Share your voice to help build a better quantum computing ecosystem.',
      //   image:
      //     'https://assets.cloud.pennylane.ai/pennylane_website/spotlights/Spotlight_UnitaryFundSurvey.png',
      //   image_right_aligned: false,
      //   alt: '',
      //   links: [
      //     {
      //       link_text: 'Take the survey',
      //       link: 'https://www.surveymonkey.com/r/qosssurvey24',
      //     },
      //   ],
      //   colours: spotlightTheme.theme_1,
      // },
    ],
  },
  /**
   * @title research_page
   * @description This spotlight is located on the research page of the website.
   * @link https://pennylane.ai/research/
   */
  research_page: {
    hidden: false,
    announcements: [
      {
       title: 'PENNYLANE LABS: ACCESS CUTTING-EDGE RESEARCH TOOLS',
       description: "Discover PennyLane Labs, an experimental module designed to accelerate your quantum research. Experiment with research tools, share your feedback, and help shape the future of PennyLane!",
       image: 'https://assets.cloud.pennylane.ai/pennylane_website/spotlights/Spotlight_PL_Labs.png',
       image_right_aligned: true,
       alt: '',
       links: [
          {
           link_text: 'Read the blog post!',
           link: '/blog/2025/01/cutting-edge-research-with-labs',
          },
        ],
        colours: spotlightTheme.theme_2,
      },
    ],
  },
}
